<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";

//import Vue from "vue";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Virements",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Virements Location",
          active: true,
        },
      ],
      enAttentes: 0,
      effectues: 0,
      order: [],
      ordersData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterVirement: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "vehicule", sortable: true, label: "Véhicule" },
        { key: "proprio", sortable: true, label: "Proprio" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "debut", sortable: true, label: "Début" },
        { key: "fin", sortable: true, label: "Fin Prévue" },
        { key: "montant", label: "Montant" },
        { key: "action", label: "Actions" },
      ],
      field: [
        { key: "vehicule", sortable: true, label: "Véhicule" },
        { key: "proprio", sortable: true, label: "Proprio" },
        { key: "montant", label: "Montant viré" },
        { key: "date", label: "Date virememnt" },
        { key: "auto", sortable: true, label: "Mode" },
        { key: "admin", label: "Admin" },
      ],
      idVirement: 0,
      virement: 0,
      typeVirement: "",
      showConfirm: false,
      virementModal: false,
      totalOrder: 0,
      totalNotPaid: 0
    };
  },
  /*computed: {
    rows() {
      return 0;
    },
  },*/
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.order = [];
      this.ordersData = [];
      const historique = await apiRequest(
        "GET",
        "admin/rmo/virement",
        undefined,
        false
      );
      if (historique && historique.data) {
        let donnees = historique.data.virements.sort((a, b) => b.id - a.id);
        this.enAttentes = donnees.enAttentes;
        this.effectues = donnees.effectues;

        donnees.forEach((element) => {
          var virement = {
            idVirement: element.id,
            vehicule: element.vehicule.marque + " " + element.vehicule.model,
            proprio:
              element.proprio.personne.lastname +
              " " +
              element.proprio.personne.firstname,
            tel:
              element.proprio.personne.indicatifTel +
              "" +
              element.proprio.personne.tel,
            date:
              element.dateVirement == null
                ? ""
                : new Date(element.dateVirement).toLocaleString("fr-FR"),
            debut:
              element.debut == null
                ? ""
                : new Date(element.debut).toLocaleString("fr-FR"),
            fin:
              element.fin == null
                ? ""
                : new Date(element.fin).toLocaleString("fr-FR"),
            montant: element.montant,
            moyen: element.moyen,
            depart: element.trajet
              ? new Date(element.trajet.dateHeureDepart).toLocaleString("fr-FR")
              : "",
            statut: element.statut,
            admin:
              element.admin === undefined
                ? ""
                : element.admin.personne.lastname +
                " " +
                element.admin.personne.firstname,
            auto: element.isAuto == true ? "AUTO" : "MANUEL",
            indicatifTel: element.proprio.personne.indicatifTel,
          };
          if (element.statut == "PAID") {
            this.order.push(virement);
            this.totalOrder = this.order.length
          }
          if (element.statut == "PENDING") {
            this.ordersData.push(virement);
            this.totalNotPaid = this.ordersData.length
          }
        });
      }
    },

    async updateCommissiion() {

      console.log("values", this.idVirement, "montant", this.virement)

      const data = await apiRequest("PUT",
        "admin/virement-update-amount",
        {
          idVirement: this.idVirement,
          newMontant: this.virement,
          location: true

        })

      if (data.status == 200) {
        //console.log("success",data)

        this.$toast.success("Montant modifiée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

      } else {
        this.$toast.error("Un problème est survenu", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

      }
      this.virementModal = false
      this.init();



    },
    showVirement(row) {
      this.virement = row.montant,
        this.idVirement = row.idVirement,
        this.virementModal = true

    },
    messageShow(row) {
      this.showMessage = true;
      this.messageContent.userId = row.id;
      console.log(row.id);
    },

    confirmeVirer(row, type) {
      this.idVirement = row.idVirement;
      this.typeVirement = type;
      this.showConfirm = true;
    },
    async virer() {
      this.showConfirm = false;

      let endpoint = "";
      if (this.typeVirement == "Automatique") {
        endpoint = "admin/rmo/virer";
      } else if (this.typeVirement == "TMoney") {
        endpoint = "admin/rmo/virer-tmoney";
      } else if (this.typeVirement == "Manuel") {
        endpoint = "admin/rmo/virer-manuel";
      } else if (this.typeVirement == "Wallet") {
        endpoint = "admin/rmo/virer-wallet";
      }

      const virer = await apiRequest(
        "POST",
        endpoint,
        { idVirement: this.idVirement },
        false
      );
      if (virer && virer.data) {


        this.$toast.success("Virement effectué avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.init();
      } else {


        this.$toast.error("Echec du virement.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.init();
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    hideRow(row) {
      if (row.clicked == true) {
        console.log("test");
      }
    },
  },
};
</script>
    
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-card header-class="bg-transparent border-primary" class="border border-primary">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Virements en attente</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ this.enAttentes }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-6">
                <b-card header-class="bg-transparent border-success" class="border border-success">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Virements effectués</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ this.effectues }}</h1>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <h4 class="card-title mt-4"></h4>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Virements en attente</a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table style="cursor: pointer" class="table-centered" :items="ordersData" :fields="fields"
                    responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                    @row-clicked="hideRow(row)">
                    <template v-slot:cell(action)="row">
                      <a v-if="row.item.indicatifTel != '+228'" href="javascript:void(0);" class="btn bg-green mr-1  mb-1"
                        v-b-tooltip.hover title="Virement Automatique" style="width: 80px"
                        @click="confirmeVirer(row.item, 'Automatique')">
                        Auto
                      </a>
                      <a v-if="row.item.indicatifTel == '+228'" href="javascript:void(0);" class="btn bg-red mr-1  mb-1"
                        v-b-tooltip.hover title="Virement TMoney" style="width: 80px"
                        @click="confirmeVirer(row.item, 'TMoney')">
                        Auto
                      </a>
                      <br />
                      <a href="javascript:void(0);" class="btn bg-red mr-1  mb-1" v-b-tooltip.hover
                        title="Virement Manuel" style="width: 80px" @click="confirmeVirer(row.item, 'Manuel')">
                        Manuel
                      </a>
                      <br />
                      <a href="javascript:void(0);" class="btn bg-blue mr-1 mb-1" v-b-tooltip.hover
                        title="Virement Wallet" style="width: 80px" @click="confirmeVirer(row.item, 'Wallet')">
                        Wallet
                      </a>
                    </template>
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>

                    <template v-slot:cell(montant)="row">

                      <div style="text-align: center" @click="showVirement(row.item)">
                        {{ row.value }}

                        <i class="mdi mdi-pencil font-size-18"></i>
                      </div>



                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="totalNotPaid" :per-page="perPage"
                          :rows="ordersData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Virements effectués</a>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filterVirement" />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table style="cursor: pointer" class="table-centered" :items="order" :fields="field" responsive="sm"
                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filterVirement" :filter-included-fields="filterOn" @filtered="onFiltered"
                    @row-clicked="hideRow(row)">
                    <template v-slot:cell(tel)="row" style="text-align: center">
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="totalOrder" :per-page="perPage"
                          :rows="order"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-center" centered size="sm" v-model="virementModal" title="Modifier le montant du virement"
      title-class="font-18">
      <form>
        <div class="row">
          <div class="col-md-6">
            <label for="">Montant</label>
          </div>
          <div class="col-md-6">
            <input type="number" v-model="virement" class="form-control" id="" />
          </div>

        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="virementModal = false">Fermer</b-button>
        <b-button variant="primary" @click="updateCommissiion">
          Modifier
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showConfirm" :title="'Voulez-vous vraiment faire un virement ' +
      typeVirement +
      ' à cet utilisateur?'
      " title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirm = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virer">Oui</b-button>
      </div>
    </b-modal>
  </Layout>
</template>
<style>
thead {
  background: #3AAA35;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #d6d2f8;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
  background: #fadddd;
  transition: all 0.5s;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: blue;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #d6d2f8;
  border-bottom: 1px solid blue;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid blue;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid blue;
}


.bg-red {
  background: #dc143c;
  color: #fff;
}

.nav-tabs .nav-link a {
  color: #fff !important;
}

.bg-green {
  background: #3AAA35;
  color: #fff;
}

.bg-blue {
  background: #0000cd;
  color: #fff;
}
</style>
    